export function getApiHost(){
    if (process.env.VUE_APP_API_URL)
        return process.env.VUE_APP_API_URL;
    return process.env.NODE_ENV === 'development' ? 'https://api.ls.net.loc' : 'https://api2.ls.net.ru';
}

export function getImagesHost(){
    if (process.env.VUE_APP_IMAGE_HOST)
        return process.env.VUE_APP_IMAGE_HOST;
    return 'https://cdn.ls.net.ru';
}

export function buildProductUrl(url, size = 340) {
    if(!size)
        return getImagesHost() + `/files/products/${url}`;
    return getImagesHost() + `/reimg/files/products/${size}x/${url}`;
}
