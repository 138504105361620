<template>
  <div class="card mt-4 mb-4">
    <div class="card-header product-top">
      <div>
        <strong>
          <a :href="'https://admin.ls.net.ru/admin/index.php?section=Product&item_id=' + product.id"
             target="_blank" rel="nofollow noopener"
             v-if="product.type !== 1"
          >
            <i>{{product.id}}</i>
            {{product.model}} {{product.model_full}}
          </a>
        </strong>

        <router-link
            v-if="showToPart"
            class="btn btn-link"
            :to="{name: 'part', params: {id: product.part_id}}"
            @click.native.stop="$emit('close')"
        >К партии</router-link>
      </div>

      <div class="btns">
        <button class="btn btn-link collapse-btn" v-if="collapse" @click.prevent="toggleCollapse">{{collapseText}}</button>
        <button class="btn btn-danger remove-btn" @click.prevent="removeAll">Удалить все фото</button>
      </div>

      <div>
        <label class="mb-0">
          <select class="form-control"
                  :class="'status-select-' + product.load_status"
                  :value="product.load_status"
                  @change="changeStatus($event.target.value)">
            <option v-for="(label, key) in statusLabels" :key="key" :value="key">{{label}}</option>
          </select>
        </label>
        <button class="btn btn-secondary ml-2" @click.prevent="nextStatus">Дальше</button>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table text-center">
          <thead>
            <tr>
              <th>ID</th>
              <th>Артикул</th>
              <th>Код 1С</th>
              <th>Пол</th>
              <th>Цвет</th>
              <th>Магазин</th>
              <th>Размеры</th>
              <th style="min-width: 150px;">Категория</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{{product.id}}</td>
              <td>{{product.sku}}</td>
              <td>{{product.code}}</td>
              <td>{{product.sex|sexLabel}}</td>
              <td>{{product.color_name}}</td>
              <td>{{product.item_location}}</td>
              <td>
                <span v-for="(size, key) in product.sizes" :key="key">{{size}} </span>
              </td>
              <td style="padding-right: 0;">
                <SelectCategory :value="product.category_id"
                                @change="changeCategory(product.id, $event)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <LoadProductPhotos
        v-if="!collapse || show"
        :product="product"
        @refreshProduct="$emit('refreshProduct', $event)"
      />
    </div>
  </div>
</template>

<script>
import LoadProductPhotos from "@/components/templates/products/LoadProductPhotos";
import SelectCategory from "@/components/templates/SelectCategory";
import apiRequest from "@/utils/apiRequest";

export default {
  name: "ProductEdit",

  components: {
    LoadProductPhotos, SelectCategory,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },

    showToPart: {
      default: false,
    },

    collapse: {
      default: false,
    },
  },

  data() {
    return {
      statusLabels: {
        0: 'Новый',
        1: 'Сфотографированный',
        2: 'Обработанный',
        3: 'Загруженный',
      },
      show: false,
    };
  },

  computed: {
    collapseText() {
      return this.show ? 'Свернуть' : 'Показать';
    },
  },

  methods: {
    changeStatus(status) {
      apiRequest.post('/products/change-status', {
        id: this.product.id,
        status,
        type: this.product.type,
      }).then(result => {
        this.$store.dispatch('loadData');
        this.$emit('changeStatus', result.load_status);
      })
    },

    nextStatus() {
      apiRequest.post('/products/next-status', {
        id: this.product.id,
        type: this.product.type,
      }).then(result => {
        this.$store.dispatch('loadData');
        this.$emit('changeStatus', result.load_status);
      })
    },

    removeAll() {
      if(!confirm('Удалить все фото?'))
        return;

      this.$store.commit('SET_PROCESSING', true);
      apiRequest.get('/products/remove-all-photos?product_id=' + this.product.id)
        .then(() => {
          this.$emit('refreshProduct', this.product);
        })
        .catch(xhr => {
          window.ErrorProcessing.showXHRError(xhr);
        })
        .finally(() => {
          this.$store.commit('SET_PROCESSING', false);
        });
    },

    toggleCollapse() {
      this.show = !this.show;
    },

    changeCategory(id, categoryId) {
      this.$store.commit('SET_PROCESSING', true);
      apiRequest.post('/products/change-category', {id, categoryId}).then(result => {
        this.$emit('changeCategory', result.zenn_category_id);
      }).finally(() => {
        this.$store.commit('SET_PROCESSING', false);
      });
    },
  },
}
</script>

<style scoped lang="scss">
  .product-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btns{
    margin-left: auto;
    margin-right: 1em;
  }

  .collapse-btn{
    margin-right: 1em;
  }

  .status-select-0{
    background: #f97575;
  }
  .status-select-1{
    background: #ffa500;
  }
  .status-select-2{
    background: #86a0e6;
  }
  .status-select-3{
    background: #73d873;
  }
</style>