<template>
  <div>
    <ProductEdit v-if="product"
                 :product="product"
                 :show-to-part="true"
                 @refreshProduct="loadProduct"
                 @changeStatus="bindChangeStatus"
                 @changeCategory="bindChangeCategory"
    />
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import ProductEdit from "@/components/templates/products/ProductEdit";

export default {
  name: "Product",

  components: {
    ProductEdit,
  },

  props: {
    id: {
      required: true,
    },
  },

  data() {
    return {
      product: null,
    };
  },

  created() {
    this.loadProduct();
  },

  beforeRouteUpdate(to, from, next) {
    next();
    this.$nextTick(this.loadProduct);
  },

  methods: {
    loadProduct() {
      apiRequest.get(`/products/${this.id}?type=${this.$route.query.type}`).then(product => {
        this.product = product;
      });
    },

    bindChangeStatus(status) {
      this.$set(this.product, 'load_status', status);
    },

    bindChangeCategory(categoryId) {
      this.$set(this.product, 'category_id', categoryId);
    },
  },
}
</script>

<style scoped>

</style>