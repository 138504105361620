<template>
  <div class="search-result">
    <ProductImage :image="imageUrl" class="search-result__image"/>
    <div class="search-result__info">
      <div class="search-result__name">
        <span>{{product.model}}<template v-if="product.type === 1"> (превью)</template></span>
        <router-link class="btn btn-link"
            :to="{name: 'part', params: {id: product.part_id}}"
            @click.native.stop="$emit('close')"
        >К партии</router-link>
      </div>
      <div class="search-result__codes">
        <span class="search-result__code">ID: <span v-html="idHtml"></span></span>
        <span class="search-result__code">SKU: <span v-html="skuHtml"></span></span>
        <span class="search-result__code">1C: <span v-html="codeHtml"></span></span>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImage from "@/components/templates/products/ProductImage";

export default {
  name: "ProductSearchResult",

  components: {
    ProductImage,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
  },

  computed: {
    imageUrl() {
      return this.product.large_image ? this.product.large_image : this.product.small_image;
    },
    
    idHtml() {
      let html = this.product.id.toString();
      return html.replace(this.searchText, `<span style="background: #fbd7a0;">${this.searchText}</span>`);
    },

    skuHtml() {
      let html = (this.product.sku || '').toString();
      return html.replace(this.searchText, `<span style="background: #fbd7a0;">${this.searchText}</span>`);
    },

    codeHtml() {
      let html = (this.product.code || '').toString();
      return html.replace(this.searchText, `<span style="background: #fbd7a0;">${this.searchText}</span>`);
    },
  },
}
</script>

<style scoped lang="scss">
  .search-result {
    padding: .3em .6em;
    color: #000;
    border-bottom: 1px #ccc solid;
    display: flex;

    &:last-of-type{
      border-bottom: none;
    }
    
    &:hover{
      cursor: pointer;
      background: #eee;
    }

    &__image{
      width: 60px;
      height: 60px;
      object-fit: cover;
    }

    &__info{
      flex: 100px 1 1;
      margin-left: 1em;
    }

    &__code{
      padding-right: 1em;
    }
  }
</style>