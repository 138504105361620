<template>
  <div class="container-fluid">
    <h1 v-if="part">{{part.name}}<template v-if="part.type === 1">(превью)</template></h1>

    <p v-if="products.length">Всего: {{products.length}} товаров</p>

    <div v-if="loaded">
      <div class="part-top">
        <div class="filters">
          <label>
            <span class="mb-1">Пол</span>
            <select class="form-control" :value="sex" @change="setSex($event.target.value)">
              <option :value="null">Любой</option>
              <option :value="0">Унисекс ({{unisexCount}})</option>
              <option :value="1">Мужской ({{manCount}})</option>
              <option :value="2">Женский ({{womanCount}})</option>
            </select>
          </label>

          <label class="ml-3">
            <span class="mb-1">Категория</span>
            <select class="form-control" :value="category" @change="setCategory($event.target.value)">
              <option value="0">----</option>
              <option v-for="category in categories"
                      :key="category.id"
                      :value="category.id"
              >{{category.name}} ({{categoriesCounts[category.id]}})</option>
            </select>
          </label>

          <label class="ml-3">
            <span class="mb-1">Статус</span>
            <select class="form-control" :value="loadStatus" @change="setLoadStatus($event.target.value)">
              <option :value="null">Любой</option>
              <option :value="0">Новые ({{statusCounts[0]}})</option>
              <option :value="1">Сфотографированные ({{statusCounts[1]}})</option>
              <option :value="2">Обработанные ({{statusCounts[2]}})</option>
              <option :value="3">Загруженные ({{statusCounts[3]}})</option>
            </select>
          </label>
        </div>

        <div class="imports">
          <a :href="csvUrl" class="btn btn-link" target="_blank" rel="nofollow noopener">Скачать CSV</a>
          <a :href="xlsxUrl" class="btn btn-link" target="_blank" rel="nofollow noopener">Скачать XLSX</a>
        </div>
      </div>

      <ProductsList :products="filteredProducts"
                    @refreshProduct="refreshProduct"
                    @changeStatus="bindChangeStatus"
                    @changeCategory="bindChangeCategory"
                    v-if="filteredProducts.length"
      />
      <p class="text-center" v-else>Нет товаров</p>
    </div>
    <div class="text-center" v-else>Loading</div>
  </div>
</template>

<script>
import apiRequest from "@/utils/apiRequest";
import ProductsList from "@/components/templates/products/ProductsList";
import {getApiHost} from '@/utils/urls';
import {mapState} from 'vuex';

export default {
  name: "Part",

  components: {
    ProductsList,
  },

  props: {
    id: {
      required: true,
    },
    sex: {
      default: null,
    },
    category: {
      default: 0,
    },
    loadStatus: {},
  },

  data() {
    return {
      part: null,
      products: [],
      loaded: false,
    };
  },

  computed: {
    ...mapState('categories', ['categories']),

    csvUrl() {
      return getApiHost() + `/apix/domi/import/part?` + this.importQueryString;
    },

    xlsxUrl() {
      return getApiHost() + `/apix/domi/import/part?format=xlsx&` + this.importQueryString;
    },

    importQueryString() {
      let query = `id=${this.id}`;

      if(!isNaN(parseInt(this.sex)))
        query += '&sex=' + parseInt(this.sex);
      if(parseInt(this.category))
        query += '&categoryId=' + parseInt(this.category);
      if(!isNaN(parseInt(this.loadStatus)))
        query += '&status=' + parseInt(this.loadStatus);

      return query;
    },

    queryString() {
      return `id=${this.id}`;
    },

    statusCounts() {
      let result = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      };

      this.products.forEach(product => {
        result[product.load_status]++;
      });

      return result;
    },

    manCount() {
      return this.products.filter(p => parseInt(p.sex) === 1).length;
    },

    womanCount() {
      return this.products.filter(p => parseInt(p.sex) === 2).length;
    },

    unisexCount() {
      return this.products.filter(p => parseInt(p.sex) === 0).length;
    },

    categoriesCounts() {
      let result = {};
      this.categories.forEach(c => {result[c.id] = 0;});
      this.products.forEach(product => {
        if(parseInt(product.category_id))
          result[product.category_id]++;
      });
      return result;
    },

    filteredProducts() {
      let sex = parseInt(this.sex);
      let category = parseInt(this.category);
      if(!category) category = 0;
      let loadStatus = parseInt(this.loadStatus);

      return this.products.filter(product => {
        if(!isNaN(sex) && product.sex !== sex)
          return false;

        if(!isNaN(loadStatus) && parseInt(product.load_status) !== loadStatus)
          return false;

        return !(category && product.category_id !== category);
      });
    },
  },

  created() {
    this.loadProducts();
    this.loadPart();
  },

  watch: {
    queryString() {
      this.loaded = false;
      this.loadProducts();
    },
  },

  methods: {
    loadPart() {
      apiRequest.get('/parts/' + this.id).then(part => {
        this.part = part;
      });
    },

    loadProducts() {
      apiRequest.get(`/products/for-part?${this.queryString}`)
          .then(products => {
            this.products = products;
            this.loaded = true;
          });
    },

    refreshProduct(product) {
      apiRequest.get(`/products/${product.id}?type=${product.type}`).then(product => {
        let index = this.products.findIndex(p => p.id === product.id);
        if(index > -1)
          this.$set(this.products, index, product);
      });
    },

    bindChangeStatus(product, status) {
      let index = this.products.findIndex(p => p.id === product.id);
      if(index > -1)
        this.$set(this.products[index], 'load_status', status);
    },

    bindChangeCategory(product, categoryId) {
      let index = this.products.findIndex(p => p.id === product.id);
      if(index > -1)
        this.$set(this.products[index], 'category_id', categoryId);
    },

    setSex(sex) {
      this.$router.push({query: {...this.$route.query, sex}})
    },

    setCategory(category) {
      this.$router.push({query: {...this.$route.query, category}})
    },

    setLoadStatus(loadStatus) {
      this.$router.push({query: {...this.$route.query, loadStatus}})
    },
  },
}
</script>

<style scoped>
  .part-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>